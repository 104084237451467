import React from 'react'
import PropTypes from 'prop-types'

import {
  FaFacebookF,
  FaTwitter,
  FaChevronLeft,
  FaChevronRight,
  FaStar,
  FaStarHalf,
  FaCheck,
} from 'react-icons/fa'
import { RiLoaderLine } from 'react-icons/ri'

const ALIASES = {
  facebook: FaFacebookF,
  twitter: FaTwitter,
  chevronLeft: FaChevronLeft,
  chevronRight: FaChevronRight,
  star: FaStar,
  starHalf: FaStarHalf,
  spinner: RiLoaderLine,
  check: FaCheck,
}

/**
 * <Describe the Icon component here>
 *
 * @component
 * @usage import Icon from '~/components/Icon'
 * @example
 * <Add an example of how Icon is used here>
 */
const IconComponent = ({ icon, ...extra }) => {
  const AliasIcon = ALIASES[icon]

  if (AliasIcon) {
    return <AliasIcon {...extra} />
  }

  return null
}

IconComponent.propTypes = {
  icon: PropTypes.string.isRequired,
}

IconComponent.defaultProps = {
  icon: '',
}

export default IconComponent
